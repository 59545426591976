import React, { useState, useEffect, useRef} from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Navigator from "../Common/Navigator";
import { Message } from "../Common/Message";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import QRCode from "react-qr-code";
import './Certificate.css';

const Course = () => {	
	const location = useLocation();
	let navigate = useNavigate();
	const user = AuthService.getCurrentUser();
	useEffect(() => {
		if(!user) {			
			navigate("/login");
			window.location.reload(false);
		};
	}, [navigate]);

  	const { tp_id } = location.state;
  	const [message, setMessage] = useState("");
	const [showmsg, setShowmsg] = useState(false);
	const [certificate, setCertificate] = useState("");
	const [company, setCompany] = useState("");
	const [textcert, setTextcert] = useState("");
	const M_URL = process.env.REACT_APP_MAIN_URL;
	//console.log("M_URL="+M_URL);
	useEffect(() => {		
		UserService.getDataForCertificate(tp_id).then(
			(response) => {
            	let data = response.data.certificate;
            	setCertificate(data);
            	let datac = response.data.company;
            	setCompany(datac);
            	let txtCert = "";
            	if(response.data.certificate.modality_id < 3){
            		txtCert = M_URL + "/validaQr/"+response.data.certificate.certificate;
            		/*
            		txtCert = 	response.data.company.name 
            						+ "\n" + response.data.certificate.name
            						+ "\n" + response.data.certificate.business_name
            						+ "\n" + "Certificado: " + response.data.certificate.certificate
            						+ "\n" + "Registro STPS: " + response.data.company.stps
            						+ "\n" + "Registro Protección Civil del Estado: " + response.data.company.pc
            						+ "\n" + "Folio: " + response.data.certificate.id
            						+ "\n" + "Código: " + response.data.certificate.training_code
										+ "\n" + "Emitido el: " + formatDate(response.data.certificate.for_date)
										+ "\n" + "Esta constancia tiene vigencia de un año a partir de la fecha de emisión";
										*/
            	}else if(response.data.certificate.modality_id == 3){
            		txtCert = M_URL + "/validaQr/"+response.data.certificate.certificate;
            		/*
            		txtCert = 	response.data.company.name            						
            						+ "\n" + "Se acredita el uso de las instalaciones del campo de prácticas del H. Cuerpo de Bomberos de León, Gto, a:"
            						+ "\n" + response.data.certificate.name 
            						+ "\n" + response.data.certificate.business_name
            						+ "\n" + "Certificado: " + response.data.certificate.certificate 
            						+ "\n" + "Capacitador: " + response.data.certificate.instructor_name
            						+ "\n" + "Registro Protección Civil Estatal: " + user.stps
            						+ "\n" + "Folio: " + response.data.certificate.id
            						+ "\n" + "Código: " + response.data.certificate.training_code            						
										+ "\n" + "Emitido el: " + formatDate(response.data.certificate.for_date)
										+ "\n" + "Esta constancia tiene vigencia de un año a partir de la fecha de emisión";
										*/
            	};
            	
            	setTextcert(txtCert);
			},
			(error) => {
				const _content = (error.response && error.response.data) 
										|| error.message || error.toString();
				setMessage(_content);
				setShowmsg(true);
			}
		);

	}, []);

	function  printer(e){
    	//e.preventDefault();
   	const bodyElement = document.getElementsByTagName('body')[0];
    	bodyElement.classList.add('printing');
    	window.print();
    	bodyElement.classList.remove('printing');
  	};

  	function formatDate(dt){
		if(dt){
			var d = new Date(dt.replace(/-/g, '\/').replace(/T.+/, ''));	//var d = new Date(dt);
			var datestring = d.getDate() + "-" + (d.getMonth()+1) + "-" + d.getFullYear();
			return datestring;
		}
	};
  	

	return (		
		<div className="w-full min-h-screen mx-auto">
			<Navigator />

			<div className="max-w-screen-lg mx-auto px-2 sm:px-6 lg:px-8">
				{ showmsg && <Message message={message} type="danger" show={showmsg} /> }
				
				<Link to="/course" key={certificate.id} state={{ register_id: certificate.register_id, course_name: certificate.name, course_id: certificate.course_id }} className="block mt-2 hover:underline">
					<span className="text-base text-red-600 font-bold">&lt;&lt; Regresar a curso <span className="text-white">{certificate.register_id}</span></span>
				</Link>

				{certificate &&(
				<div className="w-full mx-auto border my-8 p-4 relative printable" id="certificate">				
				 	{certificate.modality_id < 3 && (
				 	<div>
						<div className="flex items-start justify-center p-2">
					      <div className="text-left w-2/12">
					         <Link to="/">
					      		<img src="/img/bomberos-de-leon-medium.png" alt="BDL" className="max-w-full" />
					         </Link>
					      </div>
					      <div className="text-center w-8/12">
					      	<h1 className="pl-5 text-xl text-gray-800 font-bold uppercase">{company.name}</h1>					      	
								<h2 className="pl-5 text-lg text-gray-800 font-bold">{company.dpto}</h2>
								<p className="text-sm">{company.street} {company.noext} {company.noint}, Col. {company.colony}, {company.city}, {company.state}. C.P.{company.cp}
								<br />Tels. {company.tel1}, {company.tel2}, {company.tel3}
								<br />{company.email}
								</p>
					      </div>
					      <div className="text-right w-2/12">					         
								<p className="text-xl font-bold"><span className="text-gray-600">ID:</span> { certificate.id }</p>
								<p className="text-gray-600"><small className="text-gray-600">Código:<br/>{ certificate.training_code }</small></p>
					      </div>
					   </div>	
						<div className="flex items-center justify-center my-8 mb-4">
							<h1 className="text-xl">A través de su Departamento de Capacitación, otorga la siguiente:</h1>
						</div>
						<div className="flex items-center justify-center mt-8 mb-2">
							<h1 className="text-5xl uppercase">Constancia</h1>
						</div>					
						<div className="flex items-center justify-center my-2 w-full">
							<div className="text-center">
								<p className="text-lg">a:</p>
								<div className="border-b-2 border-gray-600 text-center px-8">
									<h2 className="text-2xl font-bold uppercase text-red-700" title={certificate.id}>{ certificate.name }</h2>
								</div>
								{certificate.group_name ? <div className="text-center"><h3 className="text-lg uppercase text-gray-500">{ certificate.group_name }</h3></div> : <div></div>  }
							</div>
						</div>
						<div className="flex items-center justify-center mt-4 p-6">
							<p className="text-xl">Por haber participado y concluido el curso de capacitación en <span className="font-bold">{certificate.course_name}</span> con una duración de <span className="font-semibold">{certificate.hours}</span> horas Teórico/Prácticas, impartido en el CENTRO DE CAPACITACIÓN DE BOMBEROS el día <span className="font-bold">{formatDate(certificate.for_date)}</span>.</p>
						</div>

						<div className="w-full flex items-start justify-center my-2 gap-8">
							<div className="w-1/2 text-center p-4">
								<img src={certificate.sign_path} alt="SignIzq" className="h-36 inline-block" />
								<hr className="border bg-gray-600 border-gray-600 my-2"/>
								<p className="text-sm">
									{certificate.sign_name}
									<br/>{company.sign_dpto}
								</p>
							</div>
							<div className="w-1/2 text-center p-4">
								<img src={certificate.instructor_signature} alt="SignDer" className="h-36 inline-block" />
								<hr className="border bg-gray-600 border-gray-600 my-2"/>
								<p className="text-sm">
									{certificate.instructor_name}
									<br/>Instructor
								</p>
							</div>
						</div>
					</div>
					)}
				 	{certificate.modality_id < 3 &&(
					<div className="absolute bottom-0 left-0 p-2 my-2 w-full">					
						<div className="flex items-center gap-4">							
							<div className="pr-3">
								<p className="text-base font-bold">{company.name}</p>
								<p className="text-sm">Certificado: {certificate.certificate}
									<br/>Registro STPS: {company.stps}
									<br/>Registro Protección Civil del Estado: {company.pc}									
									<br/>Folio: {certificate.id}. Emitido el: {formatDate(certificate.for_date)}
									<br/>Esta constancia tiene vigencia de un año a partir de la fecha de emisión
								</p>
							</div>							
							<div className="text-right ml-auto">																		
								<div style={{ height: "auto", margin: "0 auto", maxWidth: 120, width: "100%" }}>
								    <QRCode
								    size={256}
								    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
								    value={textcert}
								    viewBox={'0 0 256 256'}
								    />
								</div>
							</div>
						</div>
					</div>
					)}							

					{certificate.modality_id == 3 &&(
					<div className="absolute top-0 left-0 p-2 my-2 w-full">					
						<div className="flex items-center gap-4">							
							<div className="pr-3">
								<p className="text-base font-bold">{company.name}</p>
								<p className="text-sm">Certificado: {certificate.certificate}
									<br/>Registro Protección Civil Estatal: {user.stps}									
									<br/>Folio: {certificate.id}. Emitido el: {formatDate(certificate.for_date)}
									<br />Capacitado: { certificate.name }
									<br />Capacitador: {certificate.instructor_name}
									<br/>Esta constancia tiene vigencia de un año a partir de la fecha de emisión
								</p>
							</div>
							<div className="text-right ml-auto">																		
								<div style={{ height: "auto", margin: "0 auto", maxWidth: 120, width: "100%" }}>
								    <QRCode
								    size={256}
								    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
								    value={textcert}
								    viewBox={'0 0 256 256'}
								    />
								</div>
							</div>
						</div>
					</div>
					)}

				</div>				
				)}				

				<div className="w-full pt-4 pb-10 text-center">
					<button onClick={() => printer()} className="inline-block border bg-red-500 border-red-700 rounded-full text-lg text-white font-bold py-2 px-4 hover:bg-red-900">Imprimir</button>							
				</div>

			</div>
		</div>
	);
};

export default Course;